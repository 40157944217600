import axios from "axios";
import React, { Component } from "react";
import ReactModal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import SequenceVariableSubstituteModal from "./SequenceVariableSubstituteModal";
import JoditBulkEmail from "../SendEmail/JoditBulkEmail";
import JoditEditor from "jodit-react";

const config = {
  height: "auto",
  minHeight: "20px",
  width: "inherit",
  minWidth: "20px",
  toolbar: false,
  enableDragAndDropFileToEditor: true,
  askBeforePasteFromWord: false,
  askBeforePasteHTML: false,
  link: {
    modeClassName: false,
  },
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
  toolbarSticky: true,
  enter: "BR",
  placeholder: "Enter Subject",
  zIndex: 2147483640,
  addNewLine: false,
  allowResizeY: false,
};

class SendBulkSequenceModal extends Component {
  state = {
    showModal: true,
    windowWidth: window.innerWidth,
    sequences: [],
    isLoading: false,
    allVariables: [],
    showVarModal: false,
  };
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };
  handleOpenVarModal = () => {
    this.setState({
      showVarModal: true,
    });
  };

  handleCloseVarModal = () => {
    this.setState({
      showVarModal: false,
    });
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  dateChangeHandler = (id, date) => {
    // const offsetDate =
    //   date === null || date === ""
    //     ? new Date()
    //     : date.getTimezoneOffset() < 0
    //     ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    //     : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    this.setState((prevState) => ({
      sequences: prevState.sequences.map((seq) => {
        if (id === seq.id) {
          seq.date = date;
        }
        return seq;
      }),
    }));
  };
  timeChangeHandler = (id, date) => {
    this.setState((prevState) => ({
      sequences: prevState.sequences.map((seq) => {
        if (id === seq.id) {
          seq.time = date;
        }
        return seq;
      }),
    }));
  };
  extractPlainTextFromHTML = (html) => {
    // Create a temporary DOM element
    const tempDiv = document.createElement("div");
    // Set the inner HTML of the div
    tempDiv.innerHTML = html;
    // Return the text content, stripping out all HTML tags and unnecessary attributes
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    let allVariables = [];
    this.setState({
      sequences: this.props.selectedSequence.steps
        ?.sort((a, b) => a.order - b.order)
        .map((step, index) => {
          const date = new Date();
          let sec = date.getTime() / 1000;
          let seconds = Math.round(sec + step.delay);
          const newDate = new Date(seconds * 1000);
          let finalContent = step.body;
          let finalSubject = step.subject;
          for (let i = 0; i < step.personalize_vars.length; i++) {
            finalContent = finalContent.includes(
              `{{ ${step.personalize_vars[i].key} }}`
            )
              ? finalContent.replaceAll(
                  `{{ ${step.personalize_vars[i].key} }}`,
                  `<img src="" alt="${step.personalize_vars[i].label}" class="jodit-variable-img">`
                )
              : finalContent;
            finalSubject = finalSubject.includes(
              `{{ ${step.personalize_vars[i].key} }}`
            )
              ? finalSubject.replaceAll(
                  `{{ ${step.personalize_vars[i].key} }}`,
                  `<img src="" alt="${step.personalize_vars[i].label}" class="jodit-variable-img">`
                )
              : finalSubject;
          }
          for (let i = 0; i < step.personalize_vars.length; i++) {
            allVariables = [...allVariables, step.personalize_vars[i]];
          }
          return {
            to: this.props.toEmail,
            from: this.props.fromEmail,
            order: index + 1,
            subject: finalSubject === false ? step.subject : finalSubject,
            body:
              finalContent === false
                ? step.body + `<br/><br/>` + this.props.emailSignature
                : finalContent + `<br/><br/>` + this.props.emailSignature,
            id: step.id,
            delay: step.delay,
            showDateTime: step.delay === 0 ? "now" : "later",
            date: newDate,
            time: newDate,
            variables: step.personalize_vars,
          };
        }),
      allVariables: allVariables.filter(
        (v, i, a) => a.findIndex((t) => t.key === v.key) === i
      ),
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      let allVariables = [];
      this.setState({
        sequences: this.props.selectedSequence.steps
          ?.sort((a, b) => a.order - b.order)
          .map((step, index) => {
            const date = new Date();
            let sec = date.getTime() / 1000;
            let seconds = Math.round(sec + step.delay);
            const newDate = new Date(seconds * 1000);
            let finalContent = step.body;
            let finalSubject = step.subject;
            for (let i = 0; i < step.personalize_vars.length; i++) {
              finalContent = finalContent.includes(
                `{{ ${step.personalize_vars[i].key} }}`
              )
                ? finalContent.replaceAll(
                    `{{ ${step.personalize_vars[i].key} }}`,
                    `<img src="" alt="${step.personalize_vars[i].label}" class="jodit-variable-img">`
                  )
                : finalContent;
              finalSubject = finalSubject.includes(
                `{{ ${step.personalize_vars[i].key} }}`
              )
                ? finalSubject.replaceAll(
                    `{{ ${step.personalize_vars[i].key} }}`,
                    `<img src="" alt="${step.personalize_vars[i].label}" class="jodit-variable-img">`
                  )
                : finalSubject;
            }
            for (let i = 0; i < step.personalize_vars.length; i++) {
              allVariables = [...allVariables, step.personalize_vars[i]];
            }
            if (allVariables.length !== 0 && this.props.showOpportunityModal) {
              this.handleOpenVarModal();
            }
            return {
              to: this.props.toEmail,
              from: this.props.fromEmail,
              order: index + 1,
              subject: finalSubject === false ? step.subject : finalSubject,
              body:
                finalContent === false
                  ? step.body + `<br/><br/>` + this.props.emailSignature
                  : finalContent + `<br/><br/>` + this.props.emailSignature,
              id: step.id,
              delay: step.delay,
              showDateTime: step.delay === 0 ? "now" : "later",
              date: newDate,
              time: newDate,
              variables: step.personalize_vars,
            };
          }),
        allVariables: allVariables.filter(
          (v, i, a) => a.findIndex((t) => t.key === v.key) === i
        ),
      });
    }
  }
  subjectChangeHandler = (id, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      sequences: prevState.sequences.map((seq) => {
        if (id === seq.id) {
          seq.subject = value;
        }
        return seq;
      }),
    }));
  };
  updateContent = (id, value) => {
    this.setState((prevState) => ({
      sequences: prevState.sequences.map((seq) => {
        if (id === seq.id) {
          seq.body = value;
        }
        return seq;
      }),
    }));
  };
  updateSubject = (id, value) => {
    this.setState((prevState) => ({
      sequences: prevState.sequences.map((seq) => {
        if (id === seq.id) {
          seq.subject = value;
        }
        return seq;
      }),
    }));
  };
  setShowDateTime = (id, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      sequences: prevState.sequences.map((seq) => {
        if (id === seq.id) {
          seq.showDateTime = value;
        }
        return seq;
      }),
    }));
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  readTemplateVarsValues = (allVariables, e) => {
    e.preventDefault();
    this.setState({
      allVariables,
      showVarModal: false,
    });
  };

  sendSequence = () => {
    this.setState({
      isLoading: true,
    });
    axios({
      method: "POST",
      url: `/sequences/bulk-schedule`,
      data: {
        source: this.props.source,
        ...(this.props.toEmail.length === 0
          ? { report_id: this.props.reportId }
          : { to: this.props.toEmail }),
        ...(this.props.toEmail.length === 0 && {
          excluded_emails: this.props.excludeEmail?.filter(
            (email) => email !== null
          ),
        }),
        sequences: this.state.sequences?.map((sequence) => {
          const { from, order, subject, body, date, showDateTime, variables } =
            sequence;
          const sendTime = new Date(new Date(date).toUTCString().substr(0, 25));
          const myDate = moment(sendTime).format("YYYY-MM-DD");
          const myTime = moment(sendTime).format("HH:mm:ss");
          const nowDate = new Date(new Date().toUTCString().substr(0, 25));
          let finalContent = body;
          let finalSubject = subject;
          for (let i = 0; i < variables.length; i++) {
            finalContent = finalContent.includes(
              `<img src="" alt="${variables[i].label}" class="jodit-variable-img">`
            )
              ? finalContent.replaceAll(
                  `<img src="" alt="${variables[i].label}" class="jodit-variable-img">`,
                  `{{ ${variables[i].key} }}`
                )
              : finalContent;
            finalSubject = finalSubject.includes(
              `<img src="" alt="${variables[i].label}" class="jodit-variable-img">`
            )
              ? finalSubject.replaceAll(
                  `<img src="" alt="${variables[i].label}" class="jodit-variable-img">`,
                  `{{ ${variables[i].key} }}`
                )
              : finalSubject;
          }
          return {
            from,
            send_now: showDateTime === "now" ? true : false,
            send_at:
              showDateTime === "now"
                ? moment(nowDate).format("YYYY-MM-DD HH:mm:ss")
                : `${myDate} ${myTime}`,
            order,
            subject: this.extractPlainTextFromHTML(finalSubject),
            body: finalContent,
            sequence_id: this.props.selectedSequence.id,
            personalized_vars: variables?.map((variable) => {
              const myVar = this.state.allVariables.find(
                (v) => v.key === variable.key
              );
              return {
                key: variable.key,
                label: variable.label,
                substitute: myVar.substitute,
              };
            }),
          };
        }),
      },
    })
      .then((res) => {
        NotificationManager.success("Sequence started successfully");
        this.props.handleCloseEmailModal();
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        NotificationManager.error("Error starting sequence.");
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    console.log("seq props", this.state, this.props);
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={
                this.state.windowWidth < 600
                  ? {
                      height: "95vh",
                      top: "1%",
                      left: "0%",
                      width: "90vw",
                    }
                  : { height: "90vh", top: "2%", left: "12%", width: "70vw" }
              }
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span>
                <div className="modal__head">
                  <h2 className="modal__heading">Send Sequence</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <SequenceVariableSubstituteModal
                showOpportunityModal={this.state.showVarModal}
                setShowOpportunityModal={this.handleOpenVarModal}
                handleCloseModal={this.handleCloseVarModal}
                readTemplateVarsValues={this.readTemplateVarsValues}
                personalizedVariablesArray={this.state.allVariables}
              />
              <div style={{ height: "70vh", overflowY: "auto" }}>
                {this.state.sequences?.map((step) => {
                  return (
                    <div
                      style={{
                        border: "1px solid #eee",
                        position: "relative",
                        marginBottom: "16px",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        marginRight: "5px",
                      }}
                    >
                      <div>
                        <select
                          value={step.showDateTime}
                          onChange={(e) => this.setShowDateTime(step.id, e)}
                          style={{ width: "180px", marginRight: "20px" }}
                        >
                          <option value="now">Send now</option>
                          <option value="later">Send Later</option>
                        </select>
                        {step.showDateTime === "later" && (
                          <span
                            style={
                              this.state.windowWidth < 600
                                ? {
                                    display: "block",
                                  }
                                : {}
                            }
                          >
                            <DatePicker
                              selected={step.date}
                              todayButton="Today"
                              value={step.date}
                              onChange={(date) =>
                                this.dateChangeHandler(step.id, date)
                              }
                              placeholderText="Date"
                              autoComplete="off"
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              className="modal-datepicker"
                            />
                          </span>
                        )}
                      </div>
                      <div
                        style={{
                          borderTop: "1px solid #EDF0F2",
                          borderBottom: "1px solid #EDF0F2",
                          margin: "10px 0",
                          padding: "3px 0",
                          display: "grid",
                          gridTemplateColumns: "55px 100%",
                        }}
                      >
                        <label
                          style={{
                            fontSize: "14px",
                            display: "inline-block",
                            marginRight: "5px",
                            fontWeight: "500",
                            paddingTop: "8px",
                          }}
                        >
                          Subject
                        </label>
                        <JoditEditor
                          value={step.subject}
                          config={config}
                          onChange={(value) => {
                            this.updateSubject(step.id, value);
                          }}
                        />
                      </div>
                      <div
                        style={{
                          height: "30.2vh",
                          maxHeight: "30.2vh",
                          minHeight: "30vh",
                        }}
                      >
                        <JoditBulkEmail
                          content={step.body}
                          updateContent={(value) =>
                            this.updateContent(step.id, value)
                          }
                          triggerInputFile={this.triggerInputFile}
                          accountId={this.props.accountId}
                          opportunityId={this.props.opportunityId}
                          contactId={this.props.contactId}
                          windowWidth={this.state.windowWidth}
                          isTemplate={true}
                        />
                      </div>
                    </div>
                  );
                })}
                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="modal__confirm-button"
                    onClick={this.sendSequence}
                    disabled={this.state.isLoading}
                    style={{ right: "7%" }}
                  >
                    {this.state.isLoading ? "Starting ..." : "Start Sequence"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default SendBulkSequenceModal;
